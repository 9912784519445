import { Script } from '../index.d';

const scripts: Script[] = [
  {
    src: 'ucl.scripts.cohesion.js',
    appendTo: 'head',
    dependOn: 'components',
    properties: {
      tagular: {
        apiKey: '03ad2fa0-bade-11e9-a2a3-2a2ae2dbcce4',
        sourceKey: 'src_1PCXglf3bQJB6IxgjUojsLjmjkS',
        writeKey: 'wk_1PCXgouVOivjIgVxJUFPKlUEvQy',
        multiparty: true,
      },
    },
  },
];

export default scripts;
